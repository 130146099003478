import { ref } from 'vue'
import getCoaches from '../composables/getCoaches';
import getCollection from './getCollection';
import {generateImageURL, removeWWW} from './utilities';

 /**
 * Get single coach profile
 * @param coach (string) | coach Profile name with space
 */
const getCoach = (coach) =>{
  const baseURL = window.location.origin;
  const coachData = ref()
  const coachError = ref()
  const imgPath = baseURL + '/assets/coaches/290x290/';
  const fileExt = '.jpg';
  const regExp = /\s+/g;
  const replacement = '_';
  const profileImageURL = ref();

  let endPoint = 'coaching-profiles';
  let domain;

  if(window.location.href.includes('localhost') || window.location.href.includes('staging') || window.location.href.includes('uat')) {
    domain = 'thelmcoach.com';
  } else {
    domain = window.location.hostname.includes('www') ? removeWWW(window.location.hostname) : window.location.hostname;
  }
  endPoint = endPoint + '/' + domain + '/' + coach;

 
  const loadCoach = async() => {
    const { error, get } = getCollection(endPoint)
    coachData.value = await get();
    coachError.value = error.value;
    if(!coachData.value){
      coachError.value = 'No reader found';
      console.error('no coach found');
      return
    }

    profileImageURL.value = generateImageURL(
      coachData.value.coachProfileName, 
      imgPath,
      fileExt,
      regExp, 
      replacement
    );
  }

  return {coachData, coachError, loadCoach, profileImageURL}
}


export default getCoach;