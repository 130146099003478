<template>
  <div class="coach card">
    <div class="card-header">
      <h4 class="coach-name">
        <div class="name-status-wrap">
          <span :class="coach.availability ? coach.isBusy ? 'busy' : 'online' : 'offline'" class="coach-status-wrap">{{coach.availability ? coach.isBusy ? $t('coaches.coachBusy') : $t('coaches.coachOnline') : $t('coaches.coachOffline')}}</span>
          {{ coach.coachProfileName }}
        </div>
        <div class="rating-wrap">
            <StarIcon v-for="index in parseInt(5)" :key="index"  :class="index > parseInt(coach.rating) ? 'unfill' : ''"/> 
            <span class="reviews">{{coach.rating}}/5 {{ $t('coaches.infoRating') }} | {{coach.reviews}} {{ $t('coaches.infoReview') }}</span>
        </div>
      </h4>
      
      <div class="action-wrap flex">
        <router-link
          v-if="coach.availability && !coach.isBusy"
          :to="coach.availability ? {name: 'Chat', params: {name: coach.coachProfileName}} : ''" 
          :class="coach.availability ? coach.isBusy ? 'busy btn btn-sm btn-primary' : 'online btn btn-sm btn-primary' : 'offline btn btn-sm btn-primary'">
          <ChatBubbleBottomCenterIcon/> 
          <span v-if="coach.availability && !coach.isBusy">{{ $t('coaches.infoBtn') }}</span>
        </router-link>
          
        <a
          href="javascript:void(0)"
          v-if="!coach.availability || (coach.availability && coach.isBusy)" 
          class="ping-coach btn btn-primary btn-sm ms-2"
          :class="coach.availability ? coach.isBusy ? 'busy' : 'online' : 'ping-offline'"
          @click = pingCoach(true);>
          <BellIcon/>
          <span>{{ $t('coaches.infoPing') }} {{coach.firstname}}</span>
        </a>
      </div>
    </div>
    <div class="card-body">
      <div class="coach-pic">
        <img :src="coach.profileImageURL" loading="lazy" :alt="coach.firstname" width="150" height="150">
      </div>
      <div class="coach-details">
        <h6 class="bold mb-1">
          <b v-for="(type, i) in coach.types" :key="type">
            <b v-if="i !== 0">, </b>
            {{ $t(`coaches.${type}`) }}
          </b>
        </h6>
        <p class="mb-1 text-sm">{{ $t("coaches.infoSpecialties") }}:</p>
        <ul class="coach-specials list-circle ms-5">
          <li v-for="specialty in coach.specialties" :key="specialty">
            {{$t(`coaches.${specialty}`)}}
          </li>
        </ul>
        <router-link
          :to="{name: 'Reader', params: {name: detailRoute}}" 
          class="read-more">
          <span><ChevronDoubleRightIcon class="h-3 w-3" /> {{ $t("coaches.coachMoreAbout") }} {{coach.firstname}}</span>
        </router-link>
      </div>
    </div>

    <Modal :open="open" id="connect-to-coach">
      <template #modalTitle><span class="dynamic-text">{{ $t("coaches.coachContact") }} </span> {{coach.coachProfileName}}</template>
      <template #modalContent> 
        <Spinner/>
        <!-- focusable element tweak -->
        <button className="h-0 w-0 overflow-hidden"/> 
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import {generateImageURL, checkAvailability} from '../composables/utilities';
import requestNotification from '../composables/requestNotification';
import Modal from './utilities/Modal.vue'
import Spinner from './utilities/Spinner.vue'
import { ChatBubbleBottomCenterIcon, BellIcon, ChevronDoubleRightIcon, StarIcon } from '@heroicons/vue/24/solid'
import router from '@/router';

// Defaults: Can be alter depends on the developer
// Stays on template level
const baseURL = window.location.origin;
const imgPath = baseURL + '/assets/coaches/';
const fileExt = '.jpg';
const regExp = /\s+/g;
const replacement = '_';
const coachData = defineProps(['coach']);
const open = ref(false);

coachData.coach.profileImageURL = generateImageURL(coachData.coach?.coachProfileName, imgPath,fileExt,regExp, replacement);
var pingButton = document.querySelector(".ping-coach");
coachData.coach.firstname = ref(coachData.coach.coachProfileName.split(" ")[0]);
const detailRoute = ref(coachData.coach.coachProfileName);

// Watch for changes base on search
watch(coachData, () => {
  detailRoute.value = coachData.coach.coachProfileName;
});

const pingCoach = (modalStatus) => {
  open.value = modalStatus;
  setTimeout(function(){
    router.push({name: 'Chat', params: {name: coachData.coach.coachProfileName}, query : { status: coachData.coach.isBusy}});
  }, 3000);
}
</script>