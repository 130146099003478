import { getCustomerLang } from '@core/composables/utilities'

const pageTitles = {
  en:{
    "advice" : "Articles",
    "horoscope": "Daily Horoscope",
    "readings" : "Readings"
  },
  nl:{
    "advice": "Artikelen",
    "horoscope": "Dagelijkse Horoscoop",
    "readings": "Lezingen"
  },
  
}
// TODO: create lazy loading routes
// ref https://router.vuejs.org/guide/advanced/lazy-loading.html

export default pageTitles[getCustomerLang()]
