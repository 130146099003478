<template>
  <section class="coaches-wrap" id="coach-detail">
    <div class="container">
        <div class="inner-wrapper">
            <div class="whole-col">
                <div class="profile">
                    <div :class="`${!pageLoaded ? 'animated-background' : ''} profile-image`">
                        <img v-if="pageLoaded" :src="coachProfileImage" />
                    </div>
                    <div class="profile-details">
                        <div>
                            <div :class="`${!pageLoaded ? 'animated-background' : ''} profile-details-name`"><span v-if="pageLoaded">{{ coach.coachProfileName }}</span></div>
                            <div :class="`${!pageLoaded ? 'animated-background' : ''} profile-details-type`">
                                <span v-if="pageLoaded" v-for="(type, i) in coach.types" :key="type">
                                    <b v-if="i !== 0">, </b>
                                    {{ $t(`coaches.${type}`) }}
                                </span>
                            </div>
                        </div>
                        <div :class="`${!pageLoaded ? 'animated-background' : ''} review-btn-wrap hidden-sm`">
                            <div class="action-wrap">
                                <router-link
                                    :to="coach.availability ? {name: 'Chat', params: {name: coach.coachProfileName}} : ''" 
                                    :class="coach.availability ? 'online btn btn-sm btn-primary' : 'offline btn btn-sm btn-primary'"
                                    v-if="pageLoaded">
                                    <ChatBubbleBottomCenterIcon class="h-6 w-6" /> <span v-if="coach.availability">{{ $t("coaches.infoBtn") }}</span><span v-else>{{ $t("coaches.infoOffline") }}</span>
                                </router-link>
                                <span 
                                    v-if="!coach.availability" 
                                    class="ping-coach"
                                    @click = pingCoach(true)
                                    ><BellIcon class="h-4 w-4" v-if="pageLoaded"/>
                                    <span class="hidden sm:inline" v-if="pageLoaded">Ping {{coach.coachProfileName}}</span>
                                </span>
                            </div>
                            <div class="rating-wrap" v-if="pageLoaded">
                                <StarIcon v-for="index in parseInt(5)" :key="index"  :class="index > parseInt(coach.rating) ? 'unfill' : ''"/> 
                                <span class="reviews">{{coach.rating}}/5 {{ $t("coaches.infoRating") }} | {{coach.reviews}} {{ $t("coaches.infoReview") }}</span>
                            </div>
                        </div>
                    </div>
                    <div :class="`${!pageLoaded ? 'animated-background' : ''} review-btn-wrap visible-sm`">
                        <div class="action-wrap">
                            <router-link
                                :to="coach.availability ? {name: 'Chat', params: {name: coach.coachProfileName}} : ''" 
                                :class="coach.availability ? 'online btn btn-sm btn-primary' : 'offline btn btn-sm btn-primary'"
                                v-if="pageLoaded">
                                <ChatBubbleBottomCenterIcon class="h-6 w-6" /> <span v-if="coach.availability">{{ $t("coaches.infoBtn") }}</span><span v-else>{{ $t("coaches.infoOffline") }}</span>
                            </router-link>
                            <span 
                                v-if="!coach.availability" 
                                class="ping-coach"
                                @click = pingCoach(true)
                                ><BellIcon class="h-4 w-4" v-if="pageLoaded"/>
                                <span class="sm:inline" v-if="pageLoaded">Ping {{coach.coachProfileName}}</span>
                            </span>
                        </div>
                        <div class="rating-wrap" v-if="pageLoaded">
                            <StarIcon v-for="index in parseInt(5)" :key="index"  :class="index > parseInt(coach.rating) ? 'unfill' : ''"/> 
                            <span class="reviews">{{coach.rating}}/5 {{ $t("coaches.infoRating") }} <br> {{coach.reviews}} {{ $t("coaches.infoReview") }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="left-col">
                <div>
                    <div :class="`${!pageLoaded ? 'animated-background' : ''} age-wrapper`">
                        <h4 v-if="pageLoaded">{{ $t("coaches.infoAge") }}</h4>
                        <p>{{coach.age}}</p>
                    </div>

                    <div :class="`${!pageLoaded ? 'animated-background' : ''} location-wrapper`">
                        <h4 v-if="pageLoaded">{{ $t("coaches.infoLocation") }}</h4>
                        <p>{{coachDetails.address }}</p>
                    </div>

                    <div :class="`${!pageLoaded ? 'animated-background' : ''} specialties-wrapper`">
                        <h4 v-if="pageLoaded">{{ $t("coaches.infoSpecialties") }}</h4>
                        <ul class="coach-specials ms-5">
                            <li v-for="specialty in coach.specialties" :key="specialty">
                                {{$t(`coaches.${specialty}`)}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="right-col">
                <div class="about-wrap">
                    <UserCircleIcon v-if="pageLoaded"/>
                    <div>
                        <h3 :class="!pageLoaded ? 'animated-background' : ''"><span v-if="pageLoaded">{{ $t("coaches.infoAbout") }}</span></h3>
                        <p :class="!pageLoaded ? 'animated-background' : ''">{{ coachDetails[getCustomerLang()]?.about }}</p>
                    </div>
                </div>
                <div class="method-wrap">
                    <BookOpenIcon v-if="pageLoaded"/>
                    <div>
                        <h3 :class="!pageLoaded ? 'animated-background' : ''"><span v-if="pageLoaded">{{ $t("coaches.infoMethod") }}</span></h3>
                        <p :class="!pageLoaded ? 'animated-background' : ''">{{ coachDetails[getCustomerLang()]?.method }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal :open="open" id="connect-to-coach">
        <template #modalTitle><span class="dynamic-text">{{ $t("coaches.coachContact") }} </span> {{coach.coachProfileName}}</template>
        <template #modalContent> 
        <Spinner/>
        <!-- focusable element tweak -->
        <button className="h-0 w-0 overflow-hidden"/> 
        </template>
    </Modal>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {generateImageURL, checkAvailability, capitalizeString} from '@core/composables/utilities';
import requestNotification from '@core/composables/requestNotification';
import Modal from '@core/components/utilities/Modal.vue'
import Spinner from '@core/components/utilities/Spinner.vue'
import getCoach from '@core/composables/getCoach';
import { ChatBubbleBottomCenterIcon, BellIcon, ChevronDoubleRightIcon, StarIcon } from '@heroicons/vue/24/solid'
import { UserCircleIcon, BookOpenIcon } from '@heroicons/vue/24/outline'
import router from '@/router';
import { useRoute } from 'vue-router'
import { getCustomerLang } from '@core/composables/utilities'

// Defaults: Can be alter depends on the developer
// Stays on template level
const baseURL = window.location.origin;
const imgPath = baseURL + '/assets/coaches/290x290';
const fileExt = '.jpg';
const regExp = /\s+/g;
const replacement = '_';
const open = ref(false);
const coachProfileImage = ref();
const route = useRoute();

const coach = ref({});
const coachDetails = ref({});
const pageLoaded = ref(false);
const coachName = route.params.name;
const {coachData, coachError, loadCoach, profileImageURL} = getCoach(coachName);

onMounted(async() => {
    await loadCoach();
    coach.value = coachData.value.data;
    coachDetails.value = coach.value.details;
    coach.value.availability = checkAvailability(coach.value);

    coachProfileImage.value = profileImageURL.value
    if(coach.value) pageLoaded.value = true;
});



var pingButton = document.querySelector(".ping-coach");
const pingCoach = (modalStatus) => {
open.value = modalStatus;

setTimeout(function(){
    router.push({name: 'Chat', params: {name: coach.value.coachProfileName}});
}, 3000);
}

</script>


