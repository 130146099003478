import getCollection from './getCollection';
import { removeWWW } from './utilities';
import domainName from '@core/composables/domainName'

const getCoaches = () => {

  let endPoint = 'coaching-profiles';
  let domain;

  if (domainName.includes('uat') || domainName.includes('staging') || domainName.includes('localhost')){
    domain = 'thelmcoach.com';
  } else {
    domain = window.location.hostname.includes('www') ? removeWWW(window.location.hostname) : window.location.hostname;
  }
  endPoint = endPoint + '?domain=' + domain;

  const { error, get } = getCollection(endPoint)

  return { error, get }
}

export default getCoaches