import axios from 'axios';
import { useAuthStore } from '@core/stores';

const userAuth = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

export const axiosRequest = {
    post: postData(),
    get: getData()
};

function postData() {
    return (url, body) => {
        const requestBody = body;
        return userAuth.post(url, requestBody).then(handleResponse).catch(handleError);
    }
}

function getData() {
    return (url) => {
        const requestOptions = {
            headers: authHeader(url)
        };
        return userAuth.get(url, requestOptions).then(handleResponse).catch(handleError);
    }
}

function handleError(error) {
    return error.response;
}    

function handleResponse(response) {
    return response;
}    

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { token } = useAuthStore();
    const isLoggedIn = !!token;
    if (isLoggedIn && url) {
        return { Authorization: `Bearer ${token}` };
    } 
}