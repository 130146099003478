import { ref } from 'vue';
import axios from 'axios';
import getToken from './getToken';

const getCollection = (doc) => {

  const error = ref(null);

  axios.interceptors.request.use (
    config => {
      const token = getToken();
      config.headers['Content-Type'] = 'application/json';
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  )

  const get = async () => {
    let api =  process.env.VUE_APP_API_URL + doc;
    try{
      const response = await axios.get(api);

      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
  
      if(response.status !== 200) {
        error.value = 'no available data'
        throw new Error(error.value);
      }
    }
    catch(err){
      error.value = err.message;
    }
    
  }

  return { error, get }
}

export default getCollection