import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import { createGtm } from '@gtm-support/vue-gtm';
import Hotjar from 'vue-hotjar'
import i18n from './i18n'

const pinia = createPinia();

// Custom styles
localStorage.theme = 'light'
import './assets/scss/main.scss'

createApp(App)
.use(router)
.use(pinia)
.use(i18n)
.use(createGtm({
    id: 'GTM-NJ767RWJ',
    loadScript: process.env.NODE_ENV === 'production',
    vueRouter: router,
    // trackViewEventProperty: 'page-load'
}))
.use(Hotjar, {
    id: '3639518',
    isProduction: process.env.NODE_ENV === 'production'
})
.mount('#app')