import { createI18n } from 'vue-i18n'
import { getCustomerLang } from '@core/composables/utilities'
// per lang
import en from './translations/en.json'
import nl from './translations/nl.json'
const messages = {
  en,
  nl
}

const i18n = createI18n({
  legacy: false, // set `false`, to use Composition API
  locale: getCustomerLang(), // set locale
  fallbackLocale: 'en', // set fallback locale
  fallbackWarn: false,
  warnHtmlMessage: false,
  messages, // set locale messages
})

export default i18n;